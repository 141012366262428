import React, { useEffect, useRef } from 'react'
import { Canvas, useFrame, useThree } from '@react-three/fiber'
import { PerspectiveCamera } from '@react-three/drei'
import * as THREE from 'three'
import particlesVertexShader from './shaders/canvas_particles/vertex.ts'
import particlesFragmentShader from './shaders/canvas_particles/fragment.ts'
import gsap from 'gsap'

const Aboutscene: React.FC = () => {

  return (
    <>
      <Canvas className='aboutcnv'>
        <CameraAndControls />
        <Particles />
        {/* <Center>
          <Sparkles
            size={6}
            scale={[4, 5, 7]}
            position-y={-1}
            speed={0.5}
            count={20}
            color={new THREE.Color(0xAAAAFF)}
            noise={.5}
          />
        </Center> */}
      </Canvas>
    </>
  )
}

const CameraAndControls: React.FC = () => {
    return (
      <>
        <PerspectiveCamera makeDefault position={[0, 0, 18]} fov={35} />
      </>
    )
  }

  
  const Particles: React.FC = () => {
    const particleSize  =  0.15
    const geometry = new THREE.PlaneGeometry(10, 10, 256, 256)
    geometry.deleteAttribute('normal')
    
    const intensitiesArray = new Float32Array(geometry.attributes.position.count)
    const anglesArray = new Float32Array(geometry.attributes.position.count)

    for(let i = 0; i < geometry.attributes.position.count; i++) {
      intensitiesArray[i] = Math.random() / 2
      anglesArray[i] = Math.random() * Math.PI * 2
    }

    geometry.setAttribute('aIntensity', new THREE.BufferAttribute(intensitiesArray, 1))
    geometry.setAttribute('aAngle', new THREE.BufferAttribute(anglesArray, 1))
    const materialRef = useRef<THREE.ShaderMaterial>(null)
  
  
    const { size, viewport } = useThree()
    const particlesRef = useRef(null)


    const clock = new THREE.Clock()

    useFrame(() => {
      if (materialRef.current) {
        const elapsedTime = clock.getElapsedTime()
        materialRef.current.uniforms.uTime.value = elapsedTime
        materialRef.current.uniforms.uResolution.value.set(size.width * viewport.initialDpr, size.height * viewport.initialDpr)
        materialRef.current.uniforms.uParticleSize.value = particleSize
      }
    })
  
    if (!geometry) return null

    const indexTexture = useRef(0)
    const textures = [
      new THREE.TextureLoader().load('./tolexia_v2-nobg.png'),
      new THREE.TextureLoader().load('./webdeveloper_text.png'),
    //   new THREE.TextureLoader().load('./threejs.png'),
    ]

    const morphTextures = (init: boolean) => {
        if (materialRef.current) 
        {
            if(init) {
                gsap.fromTo(
                    materialRef.current.uniforms.uProgress,
                    { value: 0 },
                    { value: 1, duration: 3, ease: 'linear' }
                )
            }
            else{
                gsap.fromTo(
                    materialRef.current.uniforms.uProgress,
                    { value: 1 },
                    { 
                        value: 0, 
                        duration: 3, 
                        ease: 'linear', 
                        onComplete: () => {
                            if (materialRef.current) {
                                indexTexture.current = (indexTexture.current + 1) % textures.length
                                materialRef.current.uniforms.uPictureTexture.value = textures[indexTexture.current]
                                gsap.fromTo(
                                    materialRef.current.uniforms.uProgress,
                                    { value: 0 },
                                    { value: 1, duration: 3, ease: 'linear' }
                                )
                            }
                        }
                    }
                )
            }
        }
        return indexTexture.current
    }
    

    useEffect(() => {
        const timeout = setTimeout(() => {
            indexTexture.current = morphTextures(true)
            setTimeout(() => {
                morphTextures(false)
                setInterval(() => {
                    indexTexture.current = morphTextures(false)
                }, 11000);
            }, 5000)
        }, 10)
        return () => clearTimeout(timeout)
    }, [])

    const uniforms ={
        uResolution: { value: new THREE.Vector2() },
        uPictureTexture: { value: textures[0] },
        uParticleSize: { value: particleSize },
        uProgress: { value: 0 },
        uColorA: { value: new THREE.Color('#d66ba4') },
        uColorB: { value: new THREE.Color('#60a4d7') },
        uTime: { value: 0 },
    }
    return (
      <points geometry={geometry} ref={particlesRef}>
        <shaderMaterial 
          ref={materialRef}
          vertexShader={particlesVertexShader}
          fragmentShader={particlesFragmentShader}
          uniforms={uniforms}
        />
      </points>
    )
  }


export default Aboutscene