import React, { ReactNode, useState } from 'react';
import { useParams } from 'react-router-dom';
import projects from '../assets/projects_data.json';
import '../assets/projectDetails.css';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';

import { FaHtml5, FaCss3, FaJs, FaReact, FaPhp } from 'react-icons/fa';
import { SiFirebase, SiMysql, SiThreedotjs } from "react-icons/si";
import { RiSupabaseLine } from "react-icons/ri";
import { TbBrandNextjs } from "react-icons/tb";
import { SiGreensock } from "react-icons/si";
import { RiOpenaiFill } from "react-icons/ri";

type SkillIconsType = {
    [key: string]: ReactNode;
};

const skills_icons: SkillIconsType = {
    "javascript": <FaJs title="JavaScript" />,
    "React": <FaReact title="React" />,
    "openAI": <RiOpenaiFill title="OpenAI" />,
    "three.js": <SiThreedotjs title='Three.js'/>,
    "next.js": <TbBrandNextjs title='next.js'/>,
    "php": <FaPhp title="PHP" />,
    "mysql": <SiMysql title='mySQL'/>,
    "supabase": <RiSupabaseLine title='Supabase'/>,
    "firebase": <SiFirebase title='Firebase'/>,
    "gsap": <SiGreensock title="GSAP" />,
    "css": <FaCss3 title="CSS3" />,
    "html": <FaHtml5 title="HTML5" />
};

const ProjectDetail: React.FC = () => {
  const { projectCategory, projectIndex } = useParams<{ projectCategory: string; projectIndex: string }>();
  const projectData = projects[projectCategory as keyof typeof projects]?.[Number(projectIndex)];

  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  if (!projectData) {
    return <div>Project not found</div>;
  }

  const images = [
    { src: projectData.thumbnail },
    ...(projectData.illustrations || []).map(illustration => ({ src: illustration }))
  ];

  return (
    <div className="project-detail">
      <h1>{projectData.title}</h1>
      <img 
        src={projectData.thumbnail} 
        alt={projectData.title} 
        className='thumbnail' 
        onClick={() => {
          setPhotoIndex(0);
          setIsOpen(true);
        }}
        style={{ cursor: 'pointer' }}
      />
      <p>{projectData.description}</p>
      <h2>Skills</h2>
      <ul>
        {projectData.skills.map((skill: string, index) => (
          <li key={index}>
            {(skills_icons as SkillIconsType)[skill] || skill}
          </li>
        ))}
      </ul>
      {projectData.link && (
        <div className="btn" style={{"--clr": "#2dd9fe", "--clr-glow":"#00a3d5"} as  React.CSSProperties}>
            <a href={projectData.link}>See Live</a>
        </div>
      )}
      {projectData.video && (
        <video controls>
          <source src={projectData.video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}
      {projectData.illustrations && projectData.illustrations.length > 0 && (
        <div className="illustrations">
          <h2>Captures</h2>
          {projectData.illustrations.map((illustration, index) => (
            <img 
              key={index} 
              src={illustration} 
              alt={`Illustration ${index + 1}`} 
              onClick={() => {
                setPhotoIndex(index + 1);
                setIsOpen(true);
              }}
              style={{ cursor: 'pointer' }}
            />
          ))}
        </div>
      )}
      {projectData.showcases && Object.keys(projectData.showcases).length > 0 && (
        <div className="showcases">
          <h2>Artworks</h2>
          {Object.entries(projectData.showcases).map(([title, showcase], index) => (
            <div key={index}>
              <h3>{title}</h3>
              <a href={showcase.link} target="_blank" rel="noopener noreferrer">
                View 
              </a>
              {showcase.picture && <img src={showcase.picture} alt={title} />}
            </div>
          ))}
        </div>
      )}

      <Lightbox
        open={isOpen}
        close={() => setIsOpen(false)}
        slides={images}
        index={photoIndex}
      />
    </div>
  );
};

export default ProjectDetail;